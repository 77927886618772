export default {
    methods: {
        resultsMutated(mutationsList, { callback = null, once = false}) {
            let executed = false;
            mutationsList
            .forEach((node) => {
                if (node.attributeName === 'class' && node.target.classList.contains('result-element')) {
                    if (once && executed) return;
                    callback();
                    executed = true;
                }
            });
        },
    },
}