
import resultsMutated from '@/mixins/resultsMutated'
import featureComponent from '@/mixins/featureComponent'

export default {
    name: "ChatComponent",
    mixins: [resultsMutated, featureComponent],
    data: () => {
        return {
            scrollTriggered: false,
        }
    },
    computed: {
        scrollTarget() {
            return this.$refs.textarea.$el
        },
        options() {
            return {
                duration: 500,
                offset: -20,
                easing: 'easeInOutCubic',
            }
        }
    },
    methods: {
        scrollToBottom(mutationsList) {
            if (this.scrollTriggered) {
                return
            }
            this.resultsMutated(mutationsList, {callback: () => {
                this.scrollTriggered = true
                setTimeout(() => {
                    this.$vuetify.goTo(this.scrollTarget, this.options)
                    this.scrollTriggered = false
                }, 500)
            }, once: true})
            
        },
    },
}

